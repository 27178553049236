
// this is ridiculous, but it seems impossible to get shadows working without using an existing material.
// threejs should not tie the shadow framework into the material framework.

export function build(material, uniforms) {

    material.onBeforeCompile = (shader) => {

        shader.uniforms.diffuseMaps = uniforms.diffuseMaps;
        shader.uniforms.normalMaps = uniforms.normalMaps;
        shader.uniforms.roughnessMaps = uniforms.roughnessMaps;
        shader.uniforms.aoMaps = uniforms.aoMaps;
        shader.uniforms.sunPosition = uniforms.sunPosition;
        shader.uniforms.viewDirection = uniforms.viewDirection;

        // outside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <common>`,
          `
          #include <common>

          // somehow these don't get picked up automatically
          attribute vec3 tangent; 
          attribute vec3 bitangent;

          attribute vec2 uv2;

          //varying vec3 vtWorldPosition;
          varying vec3 vWorldNormal;
          varying vec3 vWorldPosition;
          varying vec2 vUv;
          varying vec2 vUv2;
          varying vec2 vUvL;
          varying mat3 vTBN;

          varying float vTerrainHeight;

          `
        );

        // inside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <project_vertex>`,
          `
          #include <project_vertex>

          vUv = uv;
          vUvL = uv / 10.0;
          vUv2 = uv2;
          vTerrainHeight = 50.0;

/*
          vec3 N = normalize(mat3(modelMatrix) * normal);
          vec3 T = normalize(mat3(modelMatrix) * tangent);
          vec3 B = normalize(mat3(modelMatrix) * bitangent);

*/
          vec3 N = normalize(normalMatrix * vec3(normal));
          vec3 T = normalize(vec3(modelMatrix * vec4(tangent, 0.0)));
          vec3 B = normalize(vec3(modelMatrix * vec4(bitangent, 0.0)));


          vTBN = mat3(T, B, N);

          `
        );

        // set positions
        shader.vertexShader = shader.vertexShader.replace(
          `#include <worldpos_vertex>`,
          `
          #include <worldpos_vertex>
          vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
          `
        );

        shader.vertexShader = shader.vertexShader.replace(
          `#include <defaultnormal_vertex>`,
          `
          #include <defaultnormal_vertex>
          vWorldNormal = normalize(vec3(modelMatrix * vec4(normal, 0.0)));
          // vNormal = normalize(mat3(normalMatrix) * normal);
          `
        );

        // outside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <common>`,
          `
          #include <common>

          varying vec2 vUv;
          varying vec2 vUvL;
          varying vec3 vWorldPosition;
          varying vec3 vWorldNormal;
          varying mat3 vTBN;
          varying float vTerrainHeight;

          uniform vec3 sunPosition;
          uniform vec3 viewDirection;
          uniform sampler2DArray diffuseMaps;
          uniform sampler2DArray normalMaps;
          uniform sampler2DArray roughnessMaps;
          uniform sampler2DArray aoMaps;

          float getSlopeFactor(vec3 normal) {

              return 1.0 - abs(dot(normal, vec3(0.0, 1.0, 0.0)));

          }

          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <map_fragment>`,
          `

          float shadowPower = 1.0;
          vec3 shadowColor = vec3(0, 0, 0);
          vec3 grassColor = vec3(0.55, 0.97, 0.09);
          vec3 rockColor = vec3(0.8, 0.8, 0.78);

          // extract diffuse maps
          vec4 grassDiffuse = texture(diffuseMaps, vec3(vUvL, 0));
          vec4 rockDiffuse = texture(diffuseMaps, vec3(vUv, 1));
          vec4 gravelDiffuse = texture(diffuseMaps, vec3(vUv, 2));
          vec4 dirtDiffuse = texture(diffuseMaps, vec3(vUv, 4));
          vec4 shearRockDiffuse = texture(diffuseMaps, vec3(vUv, 5));

          // extract normal maps
          vec4 grassNormal = texture(normalMaps, vec3(vUvL, 0));
          vec4 rockNormal = texture(normalMaps, vec3(vUv, 1));
          vec4 gravelNormal = texture(normalMaps, vec3(vUv, 2));
          vec4 dirtNormal = texture(normalMaps, vec3(vUv, 4));
          vec4 shearRockNormal = texture(normalMaps, vec3(vUv, 5));

          // extract roughness maps
          vec4 grassRoughness = texture(roughnessMaps, vec3(vUvL, 0));
          vec4 rockRoughness = texture(roughnessMaps, vec3(vUv, 1));
          vec4 gravelRoughness = texture(roughnessMaps, vec3(vUv, 2));
          vec4 dirtRoughness = texture(roughnessMaps, vec3(vUv, 4));
          vec4 shearRockRoughness = texture(roughnessMaps, vec3(vUv, 5));

          // extract ao maps
          vec4 grassAo = texture(aoMaps, vec3(vUvL, 0));
          vec4 rockAo = texture(aoMaps, vec3(vUv, 1));
          vec4 gravelAo = texture(aoMaps, vec3(vUv, 2));
          vec4 dirtAo = texture(aoMaps, vec3(vUv, 4));
          vec4 shearRockAo = texture(aoMaps, vec3(vUv, 5));

          // stylyze effects
          grassDiffuse.rgb *= grassColor;

          // TODO NORMALIZE ?
          float slope = getSlopeFactor(normalize(vWorldNormal));
          
          // Distance factor for dirt
          float distance = length(vWorldPosition.xyz - cameraPosition);
          float dirtFactor = smoothstep(5.0, 0.0, distance * (-vWorldPosition.y/vTerrainHeight));

          // TODO, this needs to be in the valleys, particularly runoff frrom mountains
          //float gravelFactor = smoothstep(1.5, 2.0, slope * (-vWorldPosition.y/vTerrainHeight));
          float gravelFactor = 0.0;

          // Slope factors
          //float grassFactor = smoothstep(0.0, 0.1, slope * (-vWorldPosition.y/(vTerrainHeight*10.0)));
          //float rockFactor = smoothstep(0.09, 0.101, slope);
          //float shearRockFactor = smoothstep(0.1, 0.2, slope);

          float grassFactor = smoothstep(0.0, 0.10, slope * (-vWorldPosition.y/(vTerrainHeight*10.0)));
          float rockFactor = smoothstep(0.09, 0.1, slope);
          float shearRockFactor = smoothstep(0.1, 0.2, slope);

/*
          // Define non-overlapping ranges for each factor
          float grassFactor = smoothstep(0.0, 1.0, slope);
          float rockFactor = smoothstep(1.0, 2.0, slope) * (1.0 - grassFactor);
          float shearRockFactor = smoothstep(2.0, 3.0, slope) * (1.0 - grassFactor - rockFactor);
*/
/*
          // Calculate material factors
          float totalFactor = grassFactor + rockFactor + shearRockFactor;

          // Prevent divide by zero
          totalFactor = max(totalFactor, 0.0001);

          // Normalize the factors
          grassFactor /= totalFactor;
          rockFactor /= totalFactor;
          shearRockFactor /= totalFactor;
*/

          // Normal maps converted to -1 to 1 range
          vec3 grassNormalVec = (grassNormal.rgb * 2.0 - 1.0);
          vec3 rockNormalVec = rockNormal.rgb * 2.0 - 1.0;
          vec3 gravelNormalVec = gravelNormal.rgb * 2.0 - 1.0;
          vec3 dirtNormalVec = (dirtNormal.rgb * 2.0 - 1.0);
          vec3 shearRockNormalVec = shearRockNormal.rgb * 2.0 - 1.0;

          // Combine normals based on factors
          vec3 combinedNormal = normalize(
              grassNormalVec * grassFactor +
              rockNormalVec * rockFactor +
              gravelNormalVec * gravelFactor +
              dirtNormalVec * dirtFactor +
              shearRockNormalVec * shearRockFactor
          );

          // diffuse
          vec4 diffuse = mix(grassDiffuse, dirtDiffuse, dirtFactor);
          diffuse = mix(diffuse, gravelDiffuse, gravelFactor);
          diffuse = mix(diffuse, rockDiffuse, rockFactor);
          diffuse = mix(diffuse, shearRockDiffuse, shearRockFactor);

          // roughness4 this is the texture rougness, there is also another float roughness in the shader code 
          // which appears to be the deafault user supplied roughness
          vec4 roughness4 = mix(grassRoughness, dirtRoughness, dirtFactor);
          roughness4 = mix(roughness4, gravelRoughness, gravelFactor);
          roughness4 = mix(roughness4, rockRoughness, rockFactor);
          roughness4 = mix(roughness4, shearRockRoughness, shearRockFactor);
          
          //
          // ao
          vec4 ao = mix(grassAo, dirtAo, dirtFactor);
          ao = mix(ao, gravelAo, gravelFactor);
          ao = mix(ao, shearRockAo, shearRockFactor);

          //diffuse.rgb += normalColor * normalFactor;
          //diffuse.rgb *= (1.0 + normalFactor * intensity);

          diffuseColor = diffuse;

          // gl_FragColor = vec4(mix(diffuse + ambient, shadowColor, (1.0 - getShadowMask()) * shadowPower), 1.0);
          // #include <dithering_fragment>
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <roughnessmap_fragment>`,
          `
          float roughnessFactor = roughness4.g * roughness;

          #ifdef USE_ROUGHNESSMAP

            vec4 texelRoughness = texture2D( roughnessMap, vRoughnessMapUv );

            // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
            // roughnessFactor *= texelRoughness.g;
            roughnessFactor = mix(roughnessFactor, texelRoughness.g, 0.5);

          #endif
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_begin>`,
          `
          vec3 forwardVector = normalize(viewDirection);
          vec3 lightDirection = normalize(-sunPosition);

          float angle = dot(forwardVector, lightDirection);
          angle = (angle * 0.5) + 0.5;

          normal = normalize(vTBN * combinedNormal);

          //vec3 fn = normal + combinedNormal * angle * 10.0;
          //normal += mix(normal, fn, angle * angle);

          vec3 fn = normal + combinedNormal;
          normal += mix(normal, fn, angle);

          #include <lights_fragment_begin>

          // calculate emissive light for stylized effect based on roughness map
          float emissiveFactor = 1.0 - roughnessFactor;

          // TODO: should also be multiplied by celestial color
          vec3 rockEmissive = 0.5 * rockColor * rockFactor;
          vec3 rockIridescence = 0.1 * rockColor * rockFactor;
          float rockFresnel = pow(1.0 - dot(forwardVector, vWorldNormal), 2.0);

          vec3 grassEmissive = 0.5 * grassColor * grassFactor;
          vec3 grassIridescence = 0.2 * grassColor * grassFactor;
          float grassFresnel = pow(1.0 - dot(forwardVector, vWorldNormal), 3.0);

//          vec3 iridescence = (grassFresnel * grassIridescence) + (rockFresnel * rockIridescence);
//          vec3 iridescence = mix(grassIridescence * grassFresnel, rockIridescence * rockFresnel, rockFactor);

          vec3 emissive = emissiveFactor * (grassEmissive + rockEmissive);
          `
        );

        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_end>`,
          `
          // Add the iridescence effect to the final lighting
          // reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse + reflectedLight.directDiffuse * emissive*iridescence, 0.0, 1.0);
          //reflectedLight.directDiffuse += clamp(iridescence, 0.0, 1.0);

          #include <lights_fragment_end>
          `
        );


    };

    return material;

/*
        shader.vertexShader = shader.vertexShader.replace(
            `#include <uv_pars_vertex>`,
            `varying vec2 vUv;
             out vec2 vUvL;
             out vec3 vNormal;
             uniform float uTime;`
        );
*/

};

