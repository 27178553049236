<!--
-->

<template>
  <div ref="rootNode" />
  <div />
</template>

<script>
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { defineModel, emits, inject, onBeforeUnmount, onMounted, ref, toRaw, watch } from 'vue';
import { gameStore } from "@/stores/game";
import * as THREE from 'three';
import cardGameBG from '../assets/water-dragon-card.png';

export default {
    name: 'CardGame',
      "components": {
      },
      "props": {
      },
      "emits": [
      ],
      setup (props, {emit}) {

        const scene = inject('scene'),
        game = gameStore(),
        textureLoader = new THREE.TextureLoader(),
        camera = inject('camera'),
        mouseInfo = inject('mouseInfo'),
        rootNode = ref(),
        selected = ref([]),
        hover = ref(),
        uiNode = ref(new THREE.Group()),
        virtualDeck = ref(new THREE.Group()),
        itemCardsSlot = ref(
          {
            place: 1,
            position: new THREE.Vector3(20.0,-5.0,-20.0),
            collection: "0x",
            tokenId: ""
          },
          {
            place: 2,
            position: new THREE.Vector3(20.0,-3.5,-20.0),
            collection: "0x",
            tokenId: ""
          },
          {
            place: 3,
            position: new THREE.Vector3(20.0,-2.0,-20.0),
            collection: "0x",
            tokenId: ""
          }
        ),
        playingCardsSlot = ref(
          {
            place: 1,
            position: new THREE.Vector3(14.0,-10.0,-20.0),
            collection: "0x",
            tokenId: ""
          },
          {
            place: 2,
            position: new THREE.Vector3(10.0,-10.0,-20.0),
            collection: "0x",
            tokenId: ""
          },
          {
            place: 3,
            position: new THREE.Vector3(6.0,-10.0,-20.0),
            collection: "0x",
            tokenId: ""
          }
        ),
        deckOfCardsSlot = ref({
            place: 0,
            type: "deck",
            position: new THREE.Vector3(20.0,-0.5,-20.0),
            collection: "0x",
            tokenId: ""
        }),
        createCard = ( w, h, r, s ) => {

          let cardWidth = w; let cardHeight = h; let cornerRadius = r;

          const shape = new THREE.Shape();

          shape.moveTo(-cardWidth / 2 + cornerRadius, -cardHeight / 2);
          shape.lineTo(cardWidth / 2 - cornerRadius, -cardHeight / 2);
          shape.quadraticCurveTo(cardWidth / 2, -cardHeight / 2, cardWidth / 2, -cardHeight / 2 + cornerRadius);
          shape.lineTo(cardWidth / 2, cardHeight / 2 - cornerRadius);
          shape.quadraticCurveTo(cardWidth / 2, cardHeight / 2, cardWidth / 2 - cornerRadius, cardHeight / 2);
          shape.lineTo(-cardWidth / 2 + cornerRadius, cardHeight / 2);
          shape.quadraticCurveTo(-cardWidth / 2, cardHeight / 2, -cardWidth / 2, cardHeight / 2 - cornerRadius);
          shape.lineTo(-cardWidth / 2, -cardHeight / 2 + cornerRadius);
          shape.quadraticCurveTo(-cardWidth / 2, -cardHeight / 2, -cardWidth / 2 + cornerRadius, -cardHeight / 2);

          // Extrude geometry with bevel
          const extrudeSettings = {
              depth: 0.02,
              bevelEnabled: true,
              bevelThickness: 0.02,
              bevelSize: 0.02,
              bevelSegments: 2
          };
          const geometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );

          // Calculate UVs for the vertices
          const uvs = [];
          const positions = geometry.attributes.position.array;
          const len = positions.length / 3;

          for (let i = 0; i < len; i++) {

              const x = positions[i * 3];
              const y = positions[i * 3 + 1];

              const u = (x + cardWidth / 2) / cardWidth;
              const v = (y + cardHeight / 2) / cardHeight;

              uvs.push(u, v);

          }
          geometry.setAttribute( 'uv', new THREE.BufferAttribute( new Float32Array( uvs ), 2 ) );
          return geometry;

        },
        globalToLocalQuat = (globalQuat, node) => {
          
            const parentQuat = new THREE.Quaternion()
            node.parent.getWorldQuaternion(parentQuat);
            parentQuat.invert();

            const localQuat = globalQuat.clone().multiply(parentQuat);

            return localQuat;

/*
            const worldToLocalMatrix = new THREE.Matrix4().copy(node.matrixWorld).invert();
            const localQuaternion = globalQuat.clone().premultiply(new THREE.Quaternion().setFromRotationMatrix(worldToLocalMatrix));


            return localQuaternion;
*/
        },
        resizeCardUi = () => {

            // set the z position (effective size) on window width
            const w = window.innerWidth,
             h = window.innerHeight,

            // simple algo to determine z distance based on width.
             z = -Math.pow(w, 1/8);

            // here we raycast to find the intersection point in space with a fixed plane on the z-axis.
            // technically we are creating a UI interface in space that follows the camera.

            const cameraDirection = new THREE.Vector3();
            camera.value.camera.getWorldDirection(cameraDirection);

            const coplanar = camera.value.camera.position.clone().add(cameraDirection.multiplyScalar(10));
            var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(cameraDirection.clone().negate(), coplanar);

            var raycaster = new THREE.Raycaster();
            var corner = new THREE.Vector2();

            corner.set(1, -1); // NDC bottom-right
            raycaster.setFromCamera(corner, camera.value.camera);
            raycaster.ray.intersectPlane(plane, uiNode.value.position);

            // create border from corner.
            uiNode.value.position
              .sub(camera.value.camera.position)
              .sub(new THREE.Vector3(2.0,-2.0,0.0));

            let i = 0;

            uiNode.value.traverse((c) => {

                i++;
              
                if (c.userData?.cardInfo?.type == 'test') {

                }

                if (c.userData?.cardInfo?.type == 'item') {

                }

                if (c.userData?.cardInfo?.type == 'playing') {

                }

            });

        },
        toggleVirtualDeck = (view) => {

            var position = new THREE.Vector3();

            if (view === "deck") {

                position.copy(uiNode.value.position.clone().add(new THREE.Vector3(-3.0,-3,0)));

            } else if (view === "game") {

                position.copy(uiNode.value.position.clone().add(new THREE.Vector3(40.0,-8,10.0)));

            }

            let alpha = 0.05;

            /*
             * node: the actor object
             * position : the intended position (optional)
             * quaternion : the intended quaternion rotation (optional)
             * alpha : the interpolation speed (default pos,quat 0.1 and 0.01 resp.)
             */
            let transition = {
    
                node: virtualDeck.value,
                position,
                alpha

            }

            game.addTransition(transition);

        },
        cardUi = () => {};

        onMounted(async () => {

            // define a base card
            const bufGeom = createCard(2.0, 3.0, 0.2, 24),
             deckMaterial = new THREE.MeshLambertMaterial();
            
//            deckMaterial.color = "0x000000";

            const deckMesh = new THREE.Mesh(bufGeom, deckMaterial);

            let cardInfo = {
              type: 'deck'
            }
            deckMesh.userData.cardInfo = cardInfo;
            deckMesh.position.set(0.0, 0.0, 0.0);

            uiNode.value.add(deckMesh);
            game.setUiView("UI_VIEW", "game");

            //const texture = new THREE.TextureLoader().load(cardGameBG); 
            const texture = textureLoader.load(cardGameBG);
            texture.minFilter = THREE.NearestFilter;
            texture.colorSpace = THREE.SRGBColorSpace;
            texture.generateMipmaps = false; 

            const material = await new THREE.MeshStandardMaterial({ 
                map: texture
            });

            for (let i=0; i < 30/3; i++) {

                // create 3 rows
                for (let j=1; j <= 3; j++) {

                    const cardMeshPosition = new THREE.Vector3(j/2 - i * 3, 3.5 * j, 5);

                    const cardMesh = new THREE.Mesh(bufGeom, material);
                    cardInfo = {
                      type: 'playing'
                    }
                    cardMesh.userData.cardInfo = cardInfo;

                    // cardMesh.position.set(j/2 - i * 3, 3.5 * j, 5);
                    cardMesh.position.copy(cardMeshPosition);

                    cardMesh.userData.cardInfo['deckPosition'] = cardMeshPosition;

                    virtualDeck.value.add(cardMesh);

                }

            }

            // add the ui and the virtual deck to the camera.
            camera.value.camera.add(toRaw(uiNode.value));
            camera.value.camera.add(toRaw(virtualDeck.value));

            // add a light to the camera to light up the cards (TODO: turn on and off when toggle)

            const pointLight = new THREE.PointLight( 0xffffff, 0, 0.1);
            pointLight.position.set(0,0,1);

//            camera.value.camera.add(pointLight);

/*
            const directionalLight = new THREE.DirectionalLight(0xffffff, 12);
            directionalLight.position.set(0, 0, 0);
            directionalLight.target.position.set(0, 0, 1);
            camera.value.camera.add(directionalLight);
            camera.value.camera.add(directionalLight.target);
*/
            // run resize once to setup the UI position
            resizeCardUi();

            // set initial position of virtual deck off screen
            const p = uiNode.value.position.clone().add(new THREE.Vector3(40.0,-8,10));
            virtualDeck.value.position.copy(p);

            // adjust rotation of the virtual Deck (this is a slidable list of all cards);
            const xRadians = 20 * (Math.PI / 180);
            const yRadians = -15 * (Math.PI / 180);
            const zRadians = -10 * (Math.PI / 180);

            virtualDeck.value.rotation.x = -xRadians; 
            virtualDeck.value.rotation.y = yRadians;
            virtualDeck.value.rotation.z = zRadians;

            // watch game state actions from pinia
            game.$onAction(
                ({
                  name, // name of the action
                  store, // store instance, same as `someStore`
                  args, // array of parameters passed to the action
                  after, // hook after the action returns or resolves
                  onError, // hook if the action throws or rejects
                }) => {

                if (name == "setHover") {

                    hover.value = game.getState("HOVER");

                    if (!hover?.value?.node) {

                        return;

                    }

                    let node = toRaw(hover.value.node);

                    if (node.position.z != node?.userData?.cardInfo?.deckPosition?.z) {

                        return;

                    }

                    if (node?.userData?.cardInfo?.type == 'playing') {

                        let tmp = node.clone();

                        const uprightQuat = new THREE.Quaternion();

                        let quaternion = globalToLocalQuat(uprightQuat, node);

                        let transition = {
                
                            node,
                            quaternion,
                            alpha: 0.1

                        }

                        game.addTransition(transition);

                    }

                }

                if (name == "unsetHover") {

                    hover.value = game.getState("HOVER");

                    if (!hover.value) {

                        return;

                    }

                    let node = toRaw(hover.value.node);

                    if (node?.userData?.cardInfo?.type == 'playing') {

                        const quaternion = new THREE.Quaternion();

                        let transition = {
                
                            node,
                            quaternion,
                            alpha: 0.1

                        }

                        game.addTransition(transition);

                    }

                }

                if (name == "remSelected") {

                    if (!selected.value) {

                        return;

                    }

                    let node = selected.value.node;

                    let position = node?.userData?.cardInfo['deckPosition'];

                    let transition = {
            
                        node,
                        position,
                        alpha: 0.1

                    }

                    game.addTransition(transition);

                }

                // to get the new value, we need to put it in this after hook
                after((change) => {

                    if (name == "setUiView") {

                        toggleVirtualDeck(store['UI_VIEW']);

                    }



                    if (name == "addSelected") {

                        const sel = toRaw(game.getState("SELECTED"));

                        var latest;

                        if (sel[sel.length-1]?.node?.userData?.cardInfo?.type == "playing") {

                            latest = sel[sel.length-1];

                        } else {

                            game.remSelected(0);
                            return;

                        }

                        const node = latest.node;

                        if (parseInt(selected.value?.node?.id)) {

                            if (node?.node?.id == selected.value?.node?.id) {

                                return;

                            } else {

                                game.remSelected(0);

                            }

                        }

                        selected.value = latest;

                        let worldPosition = new THREE.Vector3();
                        node.getWorldPosition(worldPosition);

                        if (!node?.userData?.cardInfo?.deckPosition) {

                            return;

                        }

                        let cardPosition = node.position.clone();

                        let cardWorldPosition = new THREE.Vector3();
                        node.getWorldPosition(cardPosition);

                        // move to middle of screen
                        let position = new THREE.Vector3().addVectors(cardWorldPosition, camera.value.camera.position).multiplyScalar(0.5);
                        // move towards camera
                        position.z += 7;

                        node.parent.worldToLocal(position);

                        // face towards camera
                        const quaternion = new THREE.Quaternion();

                        let transition = {
                
                            node,
                            // quaternion,
                            position,
                            alpha: 0.1

                        }

                        game.addTransition(transition);

                    }

                })

            });

            window.addEventListener('resize', resizeCardUi);

        });

        onBeforeUnmount(() => {

        });

        return {
          scene,
            camera,
            rootNode,
            mouseInfo,
            hover,
            selected,
            createCard,
            resizeCardUi,
            playingCardsSlot,
            itemCardsSlot,
            deckOfCardsSlot,
            uiNode,
            cardUi,
            virtualDeck,
            toggleVirtualDeck,
            globalToLocalQuat
        };

      }

  };
</script>

<style>
body {
margin: 0;
}

</style>
